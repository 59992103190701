<template>
    <baseContainer>
        <template v-slot:heads>
            <HeadNav/>
        </template>
        <div class="personalSpace">
            <div class="personalSpace_card">
                <div class="personalSpace_item">
                    <div class="personalSpace_content">
                        <div class="identitys">{{identityName[identity]}}</div>
                        <span class="imgs">
                             <baseAvator :url="userVo.avatar"/>
                        </span>
                        <span class="names">{{userVo.name}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="menus">
            <div class="menus_items" @click="$router.push(handleLive())">
                <div>
                    <i slot="suffix">
                        <svg class="icon" aria-hidden="true">
                            <use xlink:href="#icongerenzhongxin-wodezhibo"></use>
                        </svg>
                    </i>
                    我的直播
                </div>
                <van-icon name="arrow"/>
            </div>
            <div class="menus_items" @click="$router.push(handleCourse())">
                <div>
                    <i slot="suffix">
                        <svg class="icon" aria-hidden="true">
                            <use xlink:href="#icongerenzhongxin-wodekecheng"></use>
                        </svg>
                    </i>
                    我的课程
                </div>
                <van-icon name="arrow"/>
            </div>
            <div class="menus_items"
                 v-if="userVo.roleList.length > 1"
                 @click="$router.push('/switchIdentities')">
                <div>
                    <i slot="suffix">
                        <svg class="icon" aria-hidden="true">
                            <use xlink:href="#iconrenyuan"></use>
                        </svg>
                    </i>
                    切换身份
                </div>
                <van-icon name="arrow"/>
            </div>
        </div>
        <template v-slot:foots>
            <Foots></Foots>
        </template>
    </baseContainer>
</template>

<script>

import {mapState} from 'vuex';
import HeadNav from '../../components/headNav';
import Foots from '../../components/foots';
import constant from '../../utils/constant';

export default {
    name: "personalSpace",
    components: {
        HeadNav,
        Foots
    },
    computed: {
        ...mapState({
            identity: state => state.users.identity,
            userVo: state => state.users.userVo
        })
    },
    data() {
        return {
            identityType: constant.identityType,
            identityName: constant.identityName
        }
    },
    methods: {
        handleLive() {
            switch (this.identity) {
            case this.identityType.Teacher:
                return '/myTeLive';
            default:
                return '/myStLive';
            }
        },
        handleCourse() {
            switch (this.identity) {
            case this.identityType.Teacher:
                return '/myTeCourse';
            default:
                return '/myStCourse';
            }
        }
    }
}
</script>

<style scoped lang="less">
    .icon {
        width: 1.2em;
        height: 1.2em;
        vertical-align: -0.15em;
        fill: currentColor;
        overflow: hidden;
    }

    .personalSpace {
    }

    .personalSpace_card {
        height: 312px;
        .personalSpace_item {
            height: 230px;
            background: #1A78F9;
            position: relative;
        }
        .personalSpace_content {
            position: absolute;
            left: 40px;
            right: 40px;
            top: 52px;
            background: #fff;
            height: 260px;
            width: 670px;
            border-radius: 8px;
            box-sizing: border-box;
            padding: 48px;
            display: flex;
            align-items: center;
        }
        .identitys {
            position: absolute;
            right: 30px;
            top: 30px;
            font-size: 34px;
            color: #333333;
        }
        .imgs {
            display: inline-block;
            width: 116px;
            height: 116px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 32px;
        }
        .names {
            font-size: 40px;
            font-weight: 400;
            color: #353535;
        }
    }

    .menus {
        padding: 20px 40px 40px;
        .menus_items {
            display: flex;
            justify-content: space-between;
            font-size: 28px;
            font-weight: 400;
            color: #353535;
            line-height: 40px;
            padding: 22px 0;
            i {
                margin-right: 10px;
                line-height: 40px;
                color: #DBDADA;
            }
        }
    }
</style>